import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function Accor() {
  return (
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header>Network Essentials</Accordion.Header>
        <Accordion.Body>
            <div id="example-collapse-text_0">
                 <Card style={{ width: '18rem' }}>
                        <ListGroup variant="flush">
                        <ListGroup.Item>Introduction</ListGroup.Item>
                        <ListGroup.Item>OSI Reference Model</ListGroup.Item>
                        <ListGroup.Item>Layer 2 Networks</ListGroup.Item>
                        <ListGroup.Item>Layer 3 Networks</ListGroup.Item>
                        <ListGroup.Item>Network Design</ListGroup.Item>
                        </ListGroup>
                 </Card>
            </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="1">
        <Accordion.Header>Operating System</Accordion.Header>
        <Accordion.Body>
            <div id="example-collapse-text_4">
                    <Card style={{ width: '18rem' }}>
                            <ListGroup variant="flush">
                            <ListGroup.Item>Introduction to the Linux OS</ListGroup.Item>
                            <ListGroup.Item>Linux File System  & permission</ListGroup.Item>
                            <ListGroup.Item>Basic Linux/Unix Commands </ListGroup.Item>
                            <ListGroup.Item>Secure access to Linux - SSH</ListGroup.Item>
                            <ListGroup.Item>Network Configuration</ListGroup.Item>

                            </ListGroup>
                    </Card>
            </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Cyber Security Basics</Accordion.Header>
        <Accordion.Body>
            <div id="example-collapse-text_4">
                    <Card style={{ width: '18rem' }}>
                            <ListGroup variant="flush">
                            <ListGroup.Item>Firewall</ListGroup.Item>
                            <ListGroup.Item>VPN</ListGroup.Item>
                            <ListGroup.Item>Network Security </ListGroup.Item>
                            <ListGroup.Item>Cloud Security </ListGroup.Item>
                            </ListGroup>
                    </Card>
            </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Cloud</Accordion.Header>
        <Accordion.Body>
            <div id="example-collapse-text_4">
                    <Card style={{ width: '25rem' }}>
                            <ListGroup variant="flush">
                            <ListGroup.Item>Introduction to Cloud Infrastructure</ListGroup.Item>
                            <ListGroup.Item>IaaS, PaaS and SaaS</ListGroup.Item>                          
                            <ListGroup.Item>Public, Private and Hybrid Cloud</ListGroup.Item>
                            <ListGroup.Item>Cloud IAM - Identity & Access Management</ListGroup.Item>
                            <ListGroup.Item>Containers in the Cloud</ListGroup.Item>
                            <ListGroup.Item>Cloud Networking</ListGroup.Item>
                            <ListGroup.Item>Databases in the Cloud</ListGroup.Item>
                            <ListGroup.Item>Storage in the Cloud</ListGroup.Item>
                            <ListGroup.Item></ListGroup.Item>
                            </ListGroup>
                    </Card>
            </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>TroubleShooting</Accordion.Header>
        <Accordion.Body>
            <div id="example-collapse-text_4">
                    <Card style={{ width: '18rem' }}>
                            <ListGroup variant="flush">
                            <ListGroup.Item>Network </ListGroup.Item>
                            <ListGroup.Item>Linux Server  </ListGroup.Item>
                            <ListGroup.Item> Technical Tools  </ListGroup.Item>
                            </ListGroup>
                    </Card>
            </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Accor;