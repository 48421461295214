import React from 'react'

import {Nav,Navbar,Container} from 'react-bootstrap';
import logo from "../assets/img/slide/logo.jpg";

function NavBar() {

  return (
    <Navbar bg="dark" expand="lg" variant="dark">
      <Container>
        <a class="navbar-brand" href="/">
            <img
　　　        
　　　          src= {logo}
　　　          alt="First slide"
               width="50" height="50"
            
　　        />
        </a>
        <Navbar.Brand href="/">My Idea Learning</Navbar.Brand>
       
          <Nav className="me-auto">
          </Nav>

          <Nav className="ms-auto">
                           
          </Nav>


      </Container>
    </Navbar>
  );
}

export default NavBar;





