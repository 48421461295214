import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function CrashCourses() {
  return (
    <Container fluid>
      <h1>Crash Courses</h1>
      <br/>
        <Container>
          <h2>We have designed Crash courses as an easy entrence  for someone new in IT </h2>
        </Container>
      <br/>
      <Row>
          <Col>
            <Card style={{ width: '23rem' }}>
                <Card.Body>
                    <a class="card-block stretched-link text-decoration-none" href="/ITInfNet">
                      <Card.Title>IT Infastructure & Networking</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                      <Card.Text>
                       
                      </Card.Text>
                    </a>
                </Card.Body>
             </Card>
          </Col>
          <Col>
            <Card style={{ width: '23rem' }}>
                <Card.Body>
                <a class="card-block stretched-link text-decoration-none" href="/CyberSecurity">
                  <Card.Title>Cyber Security</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                  </Card.Text>
                  </a>
                </Card.Body>

            </Card>
          </Col>
      </Row>
      <br/>
      <Row>
        <Col>
            <Card style={{ width: '23rem' }}>
                <Card.Body>
                <a class="card-block stretched-link text-decoration-none" href="/CloudComputing">
                  <Card.Title>Cloud Computing</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                  </Card.Text>
                  </a>
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card style={{ width: '23rem' }}>
                <Card.Body>
                <a class="card-block stretched-link text-decoration-none" href="/linux">
                  <Card.Title>Linux</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                  </Card.Text>
                  </a>
                </Card.Body>
            </Card>
        </Col>
      </Row>
      <br/>
      <Row>
          <Col>
              <Card style={{ width: '23rem' }}>
                      <Card.Body>
                      <a class="card-block stretched-link text-decoration-none" href="/Python">
                        <Card.Title>Python for Begineers</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                        <Card.Text>
                          Some quick example text to build on the card title and make up the
                          bulk of the card's content.
                        </Card.Text>
                        </a>
                      </Card.Body>
              </Card>
          </Col>
          <Col>
            <Card style={{ width: '23rem' }}>
                    <Card.Body>
                    <a class="card-block stretched-link text-decoration-none" href="/WebDev">
                      <Card.Title>Web Application Development</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle>
                      <Card.Text>
                        Some quick example text to build on the card title and make up the
                        bulk of the card's content.
                      </Card.Text>
                      </a>
                    </Card.Body>
            </Card>
          </Col>
      </Row>
    </Container>
  )
}

export default CrashCourses
