import React from 'react'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Carousel_ from '../components/Carousel_.js'
import slide1 from "../assets/img/slide/ITNS.jpg";
import slide2 from "../assets/img/slide/LINUX.jpg";
import slide3 from "../assets/img/slide/PYTHON.jpg";
import slide4 from "../assets/img/slide/WB.jpg";

import slide6 from "../assets/img/slide/learn.jpg";


import Itinfnet from "../components/Itinfnet.js";
import Linux from "../components/Linux.js";
import Webdev from "../components/Webdev.js";
import Python from "../components/Python.js";



function Courses() {


  return (
    <Container class="mt-5 p-5" >
   
      <Carousel_/>
      <div class="container bg-light">
         <div>
            <h1 class="display-4 text-center">Foundation Courses</h1>       
         </div>
      </div>
      <div class="container text-center text-muted">
         <h2> For</h2>
      </div>
      <div class="container text-center text-muted">
         <h2> Absolute beginners</h2>
      </div>

      <Itinfnet/>
      <Linux/>
      <Webdev/>
      <Python/>


                        <div class="container bg-light mt-5">
                            <Row class="container mt-5">
                                <Col>
                                
                                    <svg xmlns="http://www.w3.org/2000/svg" 
                                        height="1em"
                                        width="30" 
                                        viewBox="0 0 640 512"> 
                                        <path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z"/>
                                    </svg>
                                    <h7 class="p-2 text-end">Course with Real life Scenario</h7>
                            
                                        
                                </Col>
                                <Col>
                                    <svg 
                                        width="30" 
                                        height="50"
                                        xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
                                    <h7 class="p-2 text-end">Hands On Training</h7>
                                
                                </Col>
                                <Col>
                                    <svg
                                        width="30" 
                                        height="50"
                                        xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M128 32c0-17.7-14.3-32-32-32S64 14.3 64 32V64H32C14.3 64 0 78.3 0 96s14.3 32 32 32H64V384c0 35.3 28.7 64 64 64H352V384H128V32zM384 480c0 17.7 14.3 32 32 32s32-14.3 32-32V448h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H448l0-256c0-35.3-28.7-64-64-64L160 64v64l224 0 0 352z"/></svg>
                                    <h7 class="p-2 text-end">Free Test Lesson</h7>
                                </Col>
                            </Row>
                        </div>
                        <br/>
            <hr/>
                    <div class="container bg-light mt-5">
                    <div class="row">
                        <div class="col text-end">
                            <h2 class="featurette-heading mt-5">Why learn with us ? <span class="text-muted"></span></h2>
                            <p class="lead">100% Hands On</p>
                            <p class="lead">24/7 Lab Access</p>
                            <p class="lead">Flexible Schedule</p>
                            <p class="lead">Online with Trainer</p>
                            <p class="lead">Free Test Lesson</p>
                            <p class="lead">Q & A in every session</p>
                            <p class="lead">Real Life Scenario</p>
                    
                        </div>
                    <div class="col">
                        <img src={slide6} class="img-fluid d-block w-100 h-100 "/>
                    </div>
                    </div>

            </div> 
    </Container>
  )
}

export default Courses



