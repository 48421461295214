

/*

Last Update: 03/12/2023
Update: Remove underline from React Link Section.
        Updated Carosel pictures.
Version:v1.2
Env: Prod
  

*/

import React from 'react';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from './components/Navbar.js'
import Footer from './components/Footer.js'

import Courses from './pages/Courses.js'
import CrashCourses from './pages/CrashCourses.js'


import ITInfNet from './pages/ITInfNet'
import Python from './pages/Python'
import Linux from './pages/Linux'
import WebDev from './pages/WebDev'



/*
  Add bootstrap libraries  into index.js
*/

function App() {
  return ( 
       <Router>

          <Navbar/>
  
          <Routes>
              <Route  path="/"               element={<Courses/>}/>
              <Route  path="/itinfnet"       element={<ITInfNet/>}/>
              <Route  path="/linux"          element={<Linux/>}/>
              <Route  path="/python"         element={<Python/>}/>
              <Route  path="/webdev"         element={<WebDev/>}/>
           
          </Routes>
          <Footer/>
       </Router>

  );
}

export default App;
