import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

import slide1 from "../assets/img/slide/slide-1.jpg";
import slide2 from "../assets/img/slide/slide-2.jpg";
import slide3 from "../assets/img/slide/slide-3.jpg";



function Carousel_() {
  return (
   
<div class="container">

      <section className="slider container mb-3">
　　　        <Carousel>
　　　      <Carousel.Item className='slide'>
　　　        <img
　　　          className="d-block w-100"
　　　          src= {slide1}
　　　          alt="First slide"
　　　        />
            <div class="carousel-caption text-end text-white">
              <h1>Life changing skill</h1>
            </div>
　　　      </Carousel.Item>
　　　      <Carousel.Item className='slide'>
　　　        <img
　　　          className="d-block w-100"
　　　          src={slide2}
　　　          alt="Second slide"
　　　        />
             <div class="carousel-caption text-end text-white">
                <h1> NextGen  Skill</h1>
            </div>
　　　      </Carousel.Item>
　　　      <Carousel.Item className='slide'>
　　　        <img
　　　          className="d-block w-100"
　　　          src={slide3}
　　　          alt="Third slide"
　　　        />
              <div class="carousel-caption text-end text-white ">
                  <h1> Select unique career path</h1>
              </div>
　　　      </Carousel.Item>
　　　    </Carousel>
　　　 </section>


</div>


  )
}

export default Carousel_
