import React,{ useState } from 'react'
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import * as Icon from 'react-bootstrap-icons';
import AccorLinux from '../components/AccorLinux.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Accor from '../components/Accor.js';



import Container from 'react-bootstrap/Container'


function Python() {

  const [open, setOpen] = useState(false);


  return (
    <div class =" bg-light p-3">
          <div class=" row bg-light text">
            <br/>


            <div class="jumbotron bg-light bg-light text">


            <div class="row bg-light text">
                <div class="col-sm-8">
                    <h1 class="display-4">Introduction to Linux Foundation</h1>
                    <p class="lead">  </p>
                </div>


                <div class="col-sm-4 bg-light text text-center">
                  <div class="card">
                    <div class="card-body bg-light text">
               
                      <h3>From £599</h3>
                      <h5>Duration: 4 Weeks  </h5>
                      <h5>Total Hours: 16 Hrs  </h5>
                      <a href='http://contact.myidealtd.co.uk/req/getintouch'>
                      <button type="button" class="btn btn-secondary btn-lg btn-rounded mt-3">Book Now </button>
                      </a>
                      
                    </div>
                  </div>
                </div>



                <div class=" col-8 mt-5">
                    <div class="card width: 18rem;">
                      <div class="card-body">
                            <h1 class="card-title">Prerequisites</h1>
                            <h6 class="card-subtitle mb-2 text-muted">No prerequisites for this course</h6>
                      </div>
                    </div>  
                </div>

                <h1 class="mt-5">Technology</h1>
                <div class="container text-center">
                         <br/>
                     
                          <div class="mt-4 ">
                              <Row>
                                  <Col>
                                
                                      <Card bg="light"  >
                                        <Card.Body>
                                          <Card.Title>Operating System</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                                      <Card bg="light" >
                                        <Card.Body>
                                          <Card.Title>Networking</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>Shell Scripting</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>CronTab</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                            </Row>
                            <br/>
                            <Row>
                                  <Col>
                             

                                      <Card bg="light"  >
                                        <Card.Body>
                                          <Card.Title>Virtual Server</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                             
                                      <Card bg="light" >
                                        <Card.Body>
                                          <Card.Title> Web Server</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                            
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>SSH </Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                            
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>TroubleShooting</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                            </Row>
                            <br/>
                            <Row>
                                  <Col>
                            
                                      <Card bg="light"  >
                                        <Card.Body>
                                          <Card.Title> Firewall </Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                
                                
                                  <Col>
                              
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>Software</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                            </Row>
                          </div>

                          </div>

  


                        
                        <div>
                </div> 




            </div>
               
          
               
            </div>

           



<br/>


                  <div class=" col-8 bg-light text">


                       

                        <div class=" card bg-light text">
                            <div class="card-body bg-light text">
                                <h1 class="card-title">Course content </h1>
                                <p class="card-text">5 section  * 16hrs total length</p>
                                <div class=" bg-light text">
                                <AccorLinux/>
                
                                </div>
                                  
                            </div>
                        </div>
                   </div>
          </div>                
     </div>     
  )
}

export default Python
