import React from 'react'
import slide4 from "../assets/img/slide/WB.jpg";
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function Webdev() {
  return (
    <div class="container bg-light mt-5">
    <div class="row">
        <div class="col">  
            <Link to="/webdev">
                <img src={slide4} class=" img-fluid d-block w-100 h-100"/> 
            </Link>
        </div>
        <div class="col">
            <Link to="/webdev" style={{ textDecoration:'none' }}>
                    <h4 class="text-dark mt-5">Web Application Development</h4>
            </Link>
            <p class="card-text">Beginner - Course</p>
            <p class="card-text">Length - 8 Weeks </p>
            <p class="card-text">Location -  Online  </p>
            <p class="card-text"><strong>£999</strong></p>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                <Link to="/webdev">  
                  <button type="button" class="btn btn-dark btn-lg btn-rounded">Book Now </button>
                </Link>  
            </div>
        </div>
    </div>
</div>
  )
}

export default Webdev
