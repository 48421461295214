import  Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function AccorPython() {
  return (
    <div class="container bg-light text-dark">
        <Accordion >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Introduction</Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_0 ">
                    <Card style={{ width: '28rem' }}>
                            <ListGroup variant="flush">
                            <ListGroup.Item>Django Application Overview</ListGroup.Item>
                            <ListGroup.Item>Setting up Visual Studio Code</ListGroup.Item>
                            <ListGroup.Item>Create Django Project</ListGroup.Item>
                            <ListGroup.Item>Introduction Project Structure and Admin Panel</ListGroup.Item>
          
                            </ListGroup>
                    </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Django Basics</Accordion.Header>
            <Accordion.Body>
               
                <div id="example-collapse-text_4">
                        <Card class="card text-white bg-dark mb-3"  style={{ width: '18rem' }}>
                                <ListGroup variant="flush">
                                <ListGroup.Item>Django Views</ListGroup.Item>
                                <ListGroup.Item>Static Files Django </ListGroup.Item>
                                <ListGroup.Item>User creation </ListGroup.Item>
                                <ListGroup.Item>Working with Django Signals </ListGroup.Item>
                                <ListGroup.Item> Django models </ListGroup.Item>
                      
                                <ListGroup.Item>Forms in Django</ListGroup.Item>
                        
                                </ListGroup>
                        </Card>
                </div>
            
            </Accordion.Body>
          </Accordion.Item>
        
        

          <Accordion.Item eventKey="5">
            <Accordion.Header>Advanced Django </Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_4">
                        <Card class="card text-white bg-dark mb-3"  style={{ width: '18rem' }}>
                                <ListGroup variant="flush" class="text-white bg-dark">
                                <ListGroup.Item>Configuring Email service Django  </ListGroup.Item>
                                <ListGroup.Item> Django PostgresSQL Database  Connectivity </ListGroup.Item>
                                <ListGroup.Item>Deploy Django Application into AWS </ListGroup.Item>
                                <ListGroup.Item>Gunicorn Production Server
 </ListGroup.Item>
                      
                                </ListGroup>
                        </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>



          <Accordion.Item eventKey="3">
            <Accordion.Header>Django in Real World</Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_4 ">
                        <Card style={{ width: '14rem' }}>
                                <ListGroup variant="flush">
                                   <ListGroup.Item>Deploy Django application</ListGroup.Item>                          
                            
                                </ListGroup>
                        </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>



        </Accordion>
    </div>
  );
}

export default AccorPython;