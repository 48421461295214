import React,{ useState } from 'react'


import Card from 'react-bootstrap/Card';


import AccorPython from '../components/AccorPython.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';










function Python() {




  return (
    <div class =" bg-light p-3">
          <div class=" row bg-light text">
            <br/>


            <div class="jumbotron bg-light bg-light text">


            <div class="row bg-light text">
                <div class="col-sm-8">
                    <h1 class="display-4">Introduction to Python Programming</h1>
                    <p class="lead">  </p>
                </div>

                <div class=" col-8 mt-5">
                    <div class="card width: 18rem;">
                      <div class="card-body">
                            <h1 class="card-title">Prerequisites</h1>
                            <h6 class="card-subtitle mb-2 text-muted">No prerequisites for this course</h6>
                      </div>
                    </div>  
                </div>

                <div class="col-sm-4 bg-light text text-center">
                  <div class="card">
                    <div class="card-body bg-light text">
               
                      <h3>From £599</h3>
                      <h5>Duration: 4 Weeks</h5>
                      <h5>Total Hours: 16 Hrs  </h5>
          

                      <a href='http://contact.myidealtd.co.uk/req/getintouch'>
                      <button type="button" class="btn btn-secondary btn-lg btn-rounded mt-3">Book Now </button>

                      </a>
                      
                    </div>
                  </div>
                </div>

                <h1 class="mt-5">Technology</h1>
                <div class="container text-center">
                         <br/>
                     
                          <div class="mt-4 ">
                              <Row>
                                  <Col>
                                
                                      <Card bg="light"  >
                                        <Card.Body>
                                          <Card.Title>Python</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                                      <Card bg="light" >
                                        <Card.Body>
                                          <Card.Title>PIP</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>Loops</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>Condition</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                            </Row>
                            <br/>
                            <Row>
                                  <Col>
                             

                                      <Card bg="light"  >
                                        <Card.Body>
                                          <Card.Title>Virtual Environment</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                             
                                      <Card bg="light" >
                                        <Card.Body>
                                          <Card.Title>Python Web Server</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                            
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>Panda</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                            
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>def</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                            </Row>
                            <br/>
                            <Row>
                                
                                
                                
                                 
                            </Row>
                          </div>

                          </div>

  


                        
                        <div>
                </div> 



            </div>
               
          
               
            </div>

           



<br/>


                  <div class=" col-8 bg-light text">


                       

                        <div class=" card bg-light text">
                            <div class="card-body bg-light text">
                                <h1 class="card-title">Course content </h1>
                                <p class="card-text">5 section  * 16hrs total length</p>
                                <div class=" bg-light text">
                                <AccorPython/>
                
                                </div>
                                  
                            </div>
                        </div>
                   </div>
          </div>                
     </div>     
  )
}

export default Python
