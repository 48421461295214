import  Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function AccorPython() {
  return (
    <div class="container bg-light text-dark">
        <Accordion >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Introduction</Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_0 ">
                    <Card style={{ width: '18rem' }}>
                            <ListGroup variant="flush">
                            <ListGroup.Item>Python Install in Windows / Linux / MAC</ListGroup.Item>
                            <ListGroup.Item>Introduction to Python IDEs </ListGroup.Item>
          
                            </ListGroup>
                    </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Basics of Python</Accordion.Header>
            <Accordion.Body>
               
                <div id="example-collapse-text_4">
                        <Card class="card text-white bg-dark mb-3"  style={{ width: '18rem' }}>
                                <ListGroup variant="flush">
                                <ListGroup.Item>Variables/ Comments/Syntax</ListGroup.Item>
                                <ListGroup.Item>Numbers/ String / Booleans/ Opearators</ListGroup.Item>
                                <ListGroup.Item>Lists /Tuples /Sets</ListGroup.Item>
                                <ListGroup.Item>Dictionary / Array </ListGroup.Item>
                                <ListGroup.Item>String Formatting</ListGroup.Item>
                                <ListGroup.Item>Conditions</ListGroup.Item>
                                <ListGroup.Item>Sets and Booleans</ListGroup.Item>
                                </ListGroup>
                        </Card>
                </div>
            
            </Accordion.Body>
          </Accordion.Item>
        
        

          <Accordion.Item eventKey="5">
            <Accordion.Header>Loops, Conditions and  Statements in Python</Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_4">
                        <Card class="card text-white bg-dark mb-3"  style={{ width: '18rem' }}>
                                <ListGroup variant="flush" class="text-white bg-dark">
                                <ListGroup.Item>If else Statements </ListGroup.Item>
                                <ListGroup.Item>Identation </ListGroup.Item>
                                <ListGroup.Item>For Loops </ListGroup.Item>
                                <ListGroup.Item>While Loops </ListGroup.Item>
                                <ListGroup.Item>Break and Continue </ListGroup.Item>
                                </ListGroup>
                        </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>Functions</Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_4">
                        <Card style={{ width: '18rem' }}>
                                <ListGroup variant="flush">
                                <ListGroup.Item>Create a function </ListGroup.Item>
                                <ListGroup.Item>Calling a Function </ListGroup.Item>
                                <ListGroup.Item> Arguments in Function</ListGroup.Item>
                                <ListGroup.Item>Nested Function</ListGroup.Item>
                        
                                </ListGroup>
                        </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Python in Real World</Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_4 ">
                        <Card style={{ width: '25rem' }}>
                                <ListGroup variant="flush">
                                <ListGroup.Item class=" text-white bg-dark">Automation</ListGroup.Item>
                                <ListGroup.Item class=" text-white bg-dark">Web Development</ListGroup.Item>                          
                                <ListGroup.Item class=" text-white bg-dark">Data Science and Math</ListGroup.Item>
                                </ListGroup>
                        </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>



        </Accordion>
    </div>
  );
}

export default AccorPython;