import  Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

function AccorPython() {
  return (
    <div class="container bg-light text-dark">
        <Accordion >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Introduction to Linux</Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_0 ">
                    <Card style={{ width: '28rem' }}>
                            <ListGroup variant="flush">
                            <ListGroup.Item>Linux Distributions</ListGroup.Item>
                            <ListGroup.Item>Installing Linux </ListGroup.Item>
                            <ListGroup.Item>Installing Software on Linux</ListGroup.Item>
                            <ListGroup.Item>Getting Started with the Linux Terminal</ListGroup.Item>
                            <ListGroup.Item>Working with text editors </ListGroup.Item>
          
                            </ListGroup>
                    </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>Basic Linux/Unix Commands</Accordion.Header>
            <Accordion.Body>
               
                <div id="example-collapse-text_4">
                        <Card class="card text-white bg-dark mb-3"  style={{ width: '32rem' }}>
                                <ListGroup variant="flush">
                                <ListGroup.Item>Linux Directory Structure </ListGroup.Item>
                                <ListGroup.Item>File and Directory Management Commands</ListGroup.Item>
                         
                                <ListGroup.Item>File and Directory Permissions </ListGroup.Item>
                   
                                <ListGroup.Item>File Archiving and Compression Commands</ListGroup.Item>
                         
                                <ListGroup.Item>Deleting, Copying, Moving, and Renaming Files / Folders</ListGroup.Item>
                            
                     
                                </ListGroup>
                        </Card>
                </div>
            
            </Accordion.Body>
          </Accordion.Item>
        
        

          <Accordion.Item eventKey="5">
            <Accordion.Header>Shell Scripting</Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_4">
                        <Card class="card text-white bg-dark mb-3"  style={{ width: '28rem' }}>
                                <ListGroup variant="flush" class="text-white bg-dark">
                                <ListGroup.Item>Shell Scripting Basics </ListGroup.Item>
                                <ListGroup.Item>Filters, Pipes, and Variables </ListGroup.Item>
                                <ListGroup.Item>Scheduling Jobs using Cron </ListGroup.Item>
                   
                                </ListGroup>
                        </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>Secure access to Linux - SSH
</Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_4">
                        <Card style={{ width: '28rem' }}>
                                <ListGroup variant="flush">
                                <ListGroup.Item>Linux Firewall Configration</ListGroup.Item>
                                <ListGroup.Item>Connecting to Linux using SSH</ListGroup.Item>
                                <ListGroup.Item>Linux Network Configuration</ListGroup.Item>
                        
                                </ListGroup>
                        </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Linux in Real World</Accordion.Header>
            <Accordion.Body>
                <div id="example-collapse-text_4 ">
                        <Card style={{ width: '28rem' }}>
                                <ListGroup variant="flush">
                                <ListGroup.Item class=" text-white bg-dark">Web server configuration </ListGroup.Item>

                                </ListGroup>
                        </Card>
                </div>
            </Accordion.Body>
          </Accordion.Item>



        </Accordion>
    </div>
  );
}

export default AccorPython;