import React,{ useState } from 'react'

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Container from 'react-bootstrap/Container'

import Accor from '../components/Accor.js';

function ITInfNet() {
  const [open, setOpen] = useState(false);
  return (
    <div class ="container">
          <div class="mt-4 row">
            <br/>


            <div class="jumbotron bg-light">


            <div class="row">
                <div class="col-sm-8">
                    <h1 class="display-4">IT Infrastructure & Networking Foundation - Crash Course - Beginner</h1>
                    <p class="lead">This hands-on  training course is designed for the beginners to get  the basic understanding  of IT Infrastructure and Networking  in Real world context.</p>

                </div>
                <div class="col-sm-4  ">
                  <div class="card bg-light ">
                    <div class="card-body">
                      <h3>From £499</h3>
                      <h5>Duration: 4 Weeks  </h5>
                      <h5>Total Hours: 16 Hrs  </h5>
                      <a href='http://contact.myidealtd.co.uk/req/getintouch'>
                          <button type="button" class="btn btn-secondary btn-lg btn-rounded mt-3">Book Now </button>
                      </a>
                    </div>
                  </div>
                </div>
            </div>
               
          
               
            </div>

          



<br/>


                  <div class=" col-8">

                        <div class="card width: 18rem;">
                          <div class="card-body">
                                <h1 class="card-title">Prerequisites</h1>
                                <h6 class="card-subtitle mb-2 text-muted">No prerequisites for this course</h6>
                          </div>
                        </div>
                        
                        <br/>
                        <div class="card width: 18rem;">
                          <div class="card-body">
                                <h1 class="card-title">Why this course is important </h1>
                                <p class="card-text">This course covers basics of networking, Linux Operating System, Cloud computing, product-specific skills like Cisco, AWS and also a foundation for IT certifications -  CCNA, CompTIA Network+, Security+.</p>
                         
                        
                          </div>
                        </div>

                        <div class="mt-4 card width: 18rem;">
                              <div class="card-body">
                                <h1 class="card-title">Overview</h1>
                                    <p class="card-text">

                                    This is an eight hours instructor-led course, full of hands-on labs, practical exercies for a quick start in IT Network environments and giving the ability to students to understand, manage an IT Infrastructure-based systems swiftly and efficiently, with full understanding of the underlying principles.

                                    </p>
                              </div>
                          </div>
     
                  </div>


                  <div class="container">
                         <br/>
                         <h1>Technology</h1>
                          <div class="mt-4 ">
                              <Row>
                                  <Col>
                                
                                      <Card bg="light"  >
                                        <Card.Body>
                                          <Card.Title>Operating System</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                                      <Card bg="light" >
                                        <Card.Body>
                                          <Card.Title>Networking</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>Cloud </Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>Virtualization</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                            </Row>
                            <br/>
                            <Row>
                                  <Col>
                             

                                      <Card bg="light"  >
                                        <Card.Body>
                                          <Card.Title>Laptop / Desktop</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                             
                                      <Card bg="light" >
                                        <Card.Body>
                                          <Card.Title>Application</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                            
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>VPN / Remote Access</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                            
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>TroubleShooting</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                            </Row>
                            <br/>
                            <Row>
                                  <Col>
                            
                                      <Card bg="light"  >
                                        <Card.Body>
                                          <Card.Title>Hardware </Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                               
                                      <Card bg="light" >
                                        <Card.Body>
                                          <Card.Title>Wireless and Wired Access</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                             
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>Data Center</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                                  <Col>
                              
                                  <Card bg="light">
                                        <Card.Body>
                                          <Card.Title>Software</Card.Title>
                                        </Card.Body>
                                      </Card>    
                                  </Col>
                            </Row>
                          </div>

                          </div>

                      <div class=" mt-4 card bg-light">
                            <div class="card-body">
                                  <h1 class="card-title">Course content </h1>
                                  <p class="card-text">5 section  * 14hrs total length</p>
                                  <Accor/>
                            </div>
                      </div>


                      <div class="mt-4 card width: 18rem;">
                              <div class="card-body">
                                <h1 class="card-title">What you'll learn </h1>
                                    <p class="card-text">

                                    •	Understand basic networking concepts, including switches, routers, and TCP/IP 
                                    <br/>
                                    • An advanced practical skill-set of Cloud system  including storage, networking, and computer 
                                    <br/>
                                
                                    • Quick Hands-one Demo of Network, Cloud and Security TroubleShooting.
                                    <br/>
                                 

                                    </p>
                              </div>
                          </div>
                        
                        <div>
                </div>      
          </div>
     <br/>          
     </div>     
     
  )
}

export default ITInfNet



/*

<a href='http://contact.myidealtd.co.uk/req/getintouch' target="_blank">
<a href='http://127.0.0.1/getintouch' target="_blank">


  <Button  variant=" rounded-pil btn btn-danger mb-3 ">Book Free Test Lesson</Button>

*/