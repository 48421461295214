import React from 'react'

function Footer() {
  return (

<footer class="text-center text-lg-start bg-dark text-white mt-5">

  <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">

  

    <div>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-google"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-instagram"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-linkedin"></i>
      </a>
      <a href="" class="me-4 text-reset">
        <i class="fab fa-github"></i>
      </a>
    </div>
 
  </section>


  <section class="">
    <div class="container text-center text-md-start mt-5">

      <div class="row mt-3">

        <div class="col-md-2 col-lg-3 col-xl-2 mx-auto mb-4">

          <h6 class=" fw-bold mb-4">
         My Idea Learning
          </h6>

        </div>
 
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

          <h6 class="text-uppercase fw-bold mb-4">
            Courses
          </h6>
          <p>
            <a href="#!" class="text-reset" style={{ textDecoration:'none' }}>Networking</a>
          </p>
          <p>
            <a href="#!" class="text-reset" style={{ textDecoration:'none' }}>Cloud Computing</a>
          </p>
          <p>
            <a href="#!" class="text-reset" style={{ textDecoration:'none' }}>Python</a>
          </p>
          <p>
            <a href="#!" class="text-reset" style={{ textDecoration:'none' }}>Linux</a>
          </p>
        </div>


    
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

          <h6 class="text-uppercase fw-bold mb-4">
            Feature
          </h6>
          <p>
            <a href="#!" class="text-reset" style={{ textDecoration:'none' }}>Real-life Example</a>
          </p>
          <p>
            <a href="#!" class="text-reset" style={{ textDecoration:'none' }}>Access to LAB</a>
          </p>
          <p>
            <a href="#!" class="text-reset" style={{ textDecoration:'none' }}>Hands On</a>
          </p>
          <p>
            <a href="#!" class="text-reset" style={{ textDecoration:'none' }}>Q & A</a>
          </p>
        </div>


    
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

          <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
              <p><a href="mailto:learn@myidealtd.co.uk" style={{ textDecoration:'none' }}>Email : learn@myidealtd.co.uk</a></p>
        </div>

      </div>

    </div>
  </section>

  

  

    <div class="text-center p-3" >
      © 2023 Copyright:
      <a> MyIdea Ltd</a>
    </div>

    

  

  </footer>
  )
}

export default Footer
