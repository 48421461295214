import React from 'react'
import slide3 from "../assets/img/slide/PYTHON.jpg";
import { Link } from "react-router-dom";

import Card from 'react-bootstrap/Card';

function Python() {
  return (
    <div class="container bg-light mt-5">
        <div class="row">
            <div class="col">  
                <Link to="/python">
                    <img src={slide3} class=" img-fluid d-block w-100 h-100"/> 
                </Link>
            </div>
            <div class="col">
                <Link to="/python" style={{ textDecoration:'none' }}>
                        <h4 class="text-dark mt-5">Introduction to Python</h4>
                </Link>
                <p class="card-text">Beginner - Course</p>
                <p class="card-text">Length - 4 Weeks </p>
                <p class="card-text">Location -  Online  </p>
                <p class="card-text"><strong>£599</strong></p>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <Link to="/python">  
                      <button type="button" class="btn btn-dark btn-lg btn-rounded">Book Now </button>
                    </Link>  
                </div>
            </div>
        </div>
    </div>
  )
}

export default Python
