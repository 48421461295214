import React from 'react'
import slide1 from "../assets/img/slide/ITNS.jpg";
import { Link } from "react-router-dom";

function Itinfnet() {
  return (
 
    <div class="container bg-light mt-5">
    <div class="row">
        <div class="col">
               
                <Link to="/itinfnet">
                <img src={slide1} class=" img-fluid d-block w-100 h-100"/>
                </Link>
        </div>
        <div class="col">
                <Link to="/itinfnet" style={{ textDecoration:'none' }}> 
                   <h4 class="text-dark mt-5">IT Infrastructure & Networking</h4>
                </Link>
                    <br/>    
                    <p class="card-text">Beginner - Course</p>
                    <p class="card-text">Length - 4 Weeks </p>
                    <p class="card-text">Location -  Online  </p>
                    <p class="card-text"><strong>£499</strong></p>
           
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    <Link to="/itinfnet">  
                      <button type="button" class="btn btn-dark btn-lg btn-rounded">Book Now </button>
                    </Link>  
                </div>
        </div>
    </div>
</div>
  )
}

export default Itinfnet
